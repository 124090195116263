import React, { memo, useRef, useState } from "react";
import useMeasure from "react-use-measure";
import Image from "next/image";
import { AnimatePresence, motion } from "framer-motion";

import { renderFirstTruthyNode } from "@kikoff/client-utils/src/react";
import { chunkBy } from "@kikoff/utils/src/array";
import { pick } from "@kikoff/utils/src/object";
import { cls, combineClasses } from "@kikoff/utils/src/string";

import Fade from "../animations/Fade";
import GregImg from "../assets/greg.png";
import { LogoIcon } from "../assets/Logo";
import KLink from "../navigation/KLink";

import styles from "./Messages.module.scss";

declare namespace Messages {
  interface Props {
    messages: IMessage[];
    currentParticipant?: ChatParticipant;
    onClickAttachment?(attachment: Attachment): void;
  }
}

export default React.memo(Messages);
function Messages({
  messages,
  currentParticipant = "user",
  onClickAttachment,
}: Messages.Props) {
  if (messages.length === 0) return null;

  const groupedBySender = chunkBy(
    messages,
    (prev, next) => prev.sender !== next.sender
  );

  const showKikoffLogo = ({ sender }: IMessage) =>
    currentParticipant === "user" && sender === "bot";
  const showBotIcon = ({ sender }: IMessage) =>
    currentParticipant === "agent" && sender === "bot";
  const showUserIcon = ({ sender }: IMessage) =>
    currentParticipant === "agent" && sender === "user";
  const showGregIcon = ({ sender }: IMessage) =>
    currentParticipant === "user" && sender === "greg";

  const lastSentAtRef = useRef("");

  return (
    <div className={styles.messages}>
      <AnimatePresence>
        {groupedBySender.map((group) => {
          lastSentAtRef.current = "";

          return (
            <div className={styles.group} key={group[0].id}>
              {renderFirstTruthyNode(
                showKikoffLogo(group[0]) && (
                  <div className={styles.avatar}>
                    <LogoIcon />
                  </div>
                ),
                showBotIcon(group[0]) && (
                  <div className={styles.avatar}>🤖</div>
                ),
                showUserIcon(group[0]) && (
                  <div className={styles.avatar}>🧑‍💻</div>
                ),
                showGregIcon(group[0]) && (
                  <div className={styles.avatar}>
                    <Image src={GregImg} alt="Greg" />
                  </div>
                )
              )}

              <div className={styles["group-messages"]}>
                {group.map((message, j) => {
                  const showDate = message.sentAt !== lastSentAtRef.current;
                  lastSentAtRef.current = message.sentAt;

                  return (
                    <Message
                      message={message}
                      isLast={j === group.length - 1}
                      currentParticipant={currentParticipant}
                      key={message.id}
                      showDate={showDate}
                      onClickAttachment={onClickAttachment}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
      </AnimatePresence>
    </div>
  );
}

declare namespace Message {
  interface Props {
    message: IMessage;
    isLast: boolean;
    currentParticipant?: ChatParticipant;
    showDate?: boolean;
    onClickAttachment?(attachment: Attachment): void;
  }
}

function Message({
  message,
  isLast,
  showDate,
  currentParticipant = "user",
  onClickAttachment,
}: Message.Props) {
  const { sender, content, actions } = message;

  const loading = content === MessageContent.TYPING;

  const [ref, { height, width }] = useMeasure();
  const [loadedRect, setLoadedRect] = useState<DOMRect>();
  const [done, setDone] = useState(!loading);

  const isSentMsg = sender === currentParticipant;
  const isFromBot = currentParticipant === "agent" ? sender === "bot" : false;

  return (
    <motion.div
      className={cls(
        styles.message,
        isSentMsg ? styles.sent : styles.received,
        isFromBot && styles["from-bot"]
      )}
      initial={{ height: 0, opacity: -0.5 }}
      animate={{
        height: null,
        opacity: 1,
        padding: `2px 0`,
      }}
    >
      {showDate && (
        <div
          className={cls("text:mini my-1 w-full", isSentMsg && "text-right")}
        >
          {message.sentAt}
        </div>
      )}
      {!loading && !loadedRect && !done && (
        <div className={`${styles.bubble} ${styles.measure}`}>
          <div
            ref={(el) => {
              if (el) setLoadedRect(el.getBoundingClientRect());
            }}
          >
            {content}
          </div>
        </div>
      )}
      <div className={combineClasses(styles.bubble, isLast && styles.last)}>
        {done ? (
          content
        ) : (
          <motion.div
            animate={{ width, height }}
            onAnimationComplete={() => {
              if (loadedRect) {
                setLoadedRect(null);
                setDone(true);
              }
            }}
          >
            <div
              ref={ref}
              className={styles.content}
              style={loadedRect ? pick(loadedRect, ["width", "height"]) : {}}
            >
              <AnimatePresence>
                {loading ? (
                  <Fade key="loading">
                    <LoadingIndicator />
                  </Fade>
                ) : (
                  <Fade key="content">{content}</Fade>
                )}
              </AnimatePresence>
            </div>
          </motion.div>
        )}

        {message.attachments?.length > 0 && (
          <div className={cls(styles["attachments"], "mt-1")}>
            {message.attachments?.map((attachment, i) => {
              const key = message.id + i;

              return (
                <MessageAttachment
                  key={key}
                  attachment={attachment}
                  onClickAttachment={onClickAttachment}
                />
              );
            })}
          </div>
        )}
      </div>

      {typeof actions === "function" ? actions(message) : actions}
    </motion.div>
  );
}

function LoadingIndicator() {
  return (
    <div className={styles["loading-indicator"]}>
      <div />
      <div />
      <div />
    </div>
  );
}

export const MessageAttachment = memo(function MessageAttachment({
  attachment,
  onClickAttachment,
}: {
  attachment: Attachment;
  onClickAttachment?(attachment: Attachment): void;
}) {
  if (!attachment) return null;
  const { fileName, mimeType, url } = attachment;

  if (mimeType.startsWith("image")) {
    return (
      <div
        className={cls(
          styles["attachment-image"],
          !!onClickAttachment && styles["clickable"]
        )}
        onClick={() => onClickAttachment?.(attachment)}
      >
        <img src={url} alt={fileName ?? "image attachment"} />
      </div>
    );
  }

  return (
    <KLink
      href={url}
      newTab
      type="pdf"
      className={cls(styles["attachment-pdf"], "text:regular++")}
      variant="unstyled"
    >
       {fileName ?? "attachment.pdf"}
    </KLink>
  );
});

enum MessageContent {
  TYPING,
  START,
}

type Content =
  | {
      content: string | MessageContent;
    }
  | {
      content: React.ReactNode;
      textContent: string;
    };

type ChatParticipant = "user" | "bot" | "agent" | "greg";

type Attachment = {
  fileName?: string | null;
  mimeType?: string | null;
  url?: string;
};

export type IMessage = {
  sender: ChatParticipant;
  sentAt?: string;
  id?: string;
  actions?: React.ReactNode | ((message: IMessage) => React.ReactNode);
  attachments?: Attachment[];
} & Content;
