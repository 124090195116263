/* eslint-disable react/jsx-props-no-spreading */
/**
 * @file Contains custom input components
 */
import React, { forwardRef, useEffect, useRef, useState } from "react";
import gsap from "gsap";

import { createDragHandler, onRadioChange } from "@kikoff/client-utils/src/dom";
import useCombinedRefs from "@kikoff/hooks/src/useCombinedRefs";
import useUpdateEffect from "@kikoff/hooks/src/useUpdateEffect";
import { clamp } from "@kikoff/utils/src/number";
import { pick } from "@kikoff/utils/src/object";
import { combineClasses } from "@kikoff/utils/src/string";

import styles from "./index.module.scss";

let Inputmask;
// eslint-disable-next-line global-require
if (process.browser) Inputmask = require("inputmask");

/**
 * React.createElement shorthand
 */
const el = React.createElement;

interface TextInputAction {
  component: React.ReactNode;
  [key: string]: any;
}

interface _TextInputProps {
  open?: boolean;
  valid?: boolean;
  containerProps?;
  actions?: (TextInputAction | string)[];
  injection?: { [key: string]: any };
  Element?;
  mask?;
  label?: string;
  abbreviation?: string;
  invalid?: boolean;
  background?: string;
  validate?: (str: string) => boolean;
  format?: (str: string) => string;
  onChange?: (
    e: React.FormEvent<HTMLInputElement>,
    extra: { injection: _TextInputProps["injection"] }
  ) => void;
  onFocus?: (
    e: React.FormEvent<HTMLInputElement>,
    extra: { injection: _TextInputProps["injection"] }
  ) => void;
  onBlur?: (
    e: React.FormEvent<HTMLInputElement>,
    extra: { injection: _TextInputProps["injection"] }
  ) => void;

  openProps?: { [key: string]: any };
  focusProps?: { [key: string]: any };
  validProps?: { [key: string]: any };
  variant?: "default" | "outlined";
}

export type TextInputProps = Omit<
  React.HTMLProps<HTMLInputElement>,
  keyof _TextInputProps | "ref"
> &
  _TextInputProps;

/**
 * Builds a form group with an uncontrolled text input and an animated label
 *
 * @param {Object} props Contains component properties
 * @param {String} props.name Name of input
 * @param {String} props.id ID of input
 * @param {String} [props.type="text"]
 * @param {String} [props.defaultValue] Default value for uncontrolled input
 * @param {String} [props.className] Class name added to "group"
 * @param {String} [props.style] Style of input container
 * @returns {React.Component} Stylized input component
 * @deprecated Use "@kikoff/components/src/v1/inputs/TextInput" instead
 */
export const TextInput = forwardRef<HTMLDivElement, TextInputProps>(
  (
    {
      open: _open,
      valid: _valid,
      containerProps = {},
      className,
      actions,
      injection,
      Element = "input",
      mask,
      label,
      abbreviation,
      type,
      background,
      style,
      validate,
      format,
      onChange,
      onFocus,
      onBlur,
      openProps,
      focusProps,
      validProps,
      children,
      variant = "default",
      ...props
    },
    _ref
  ) => {
    const [open, setOpen] = useState(!!props.defaultValue || !!props.value);
    const [focused, setFocused] = useState(false);
    const [valid, setValid] = useState(true);
    const [_label, setLabel] = useState(label);
    const [show, setShow] = useState(type !== "password");
    const ref = useRef(null);
    const refs = useCombinedRefs(ref, _ref);

    useEffect(() => {
      const root = ref.current;
      mask &&
        Inputmask(...[mask instanceof Array ? mask : [mask]]).mask(
          root.firstChild
        );

      if (abbreviation) {
        onresize();
        window.addEventListener("resize", onresize);
        return () => {
          window.removeEventListener("resize", onresize);
        };
      }
      function onresize() {
        setLabel(
          root.querySelector(`.full`).clientWidth > root.firstChild.clientWidth
            ? abbreviation
            : label
        );
      }
    }, []);
    useEffect(() => {
      setLabel(label);
    }, [label]);
    useEffect(() => {
      if (typeof _open === "boolean") setOpen(_open);
    }, [_open]);
    useEffect(() => {
      if (typeof _valid === "boolean") setValid(_valid);
    }, [_valid]);

    if (actions) {
      // eslint-disable-next-line no-param-reassign
      actions = actions.map((action) =>
        Object.assign(
          {
            "show/hide": {
              onClick() {
                setShow(!show);
              },
              component: (
                <span style={{ cursor: "pointer", marginRight: "10px" }}>
                  {show ? "" : ""}
                </span>
              ),
            },
            send: {
              component: (
                <input className="send button" type="submit" value="Send" />
              ),
            },
          }[typeof action === "string" ? action : action.alias] || {},
          typeof action === "object" && action
        )
      );
    }
    return (
      <div
        ref={refs}
        className={combineClasses(
          styles.group,
          styles["text-input"],
          "text-input",
          className,
          styles[variant],
          {
            [styles.open]: open,
            [styles.focused]: focused,
            [styles.valid]: valid,
          }
        )}
        style={
          {
            "--input-background": background || "var(--dugout)",
            ...style,
          } as React.CSSProperties
        }
        id={containerProps.id}
      >
        <Element
          style={{
            ...pick(style, {
              fontSize: "15px",
            }),
            paddingRight: !actions && "10px",
          }}
          placeholder=""
          type={show ? (type !== "password" ? type : "text") : "password"}
          onChange={(e) => {
            if (typeof e.target?.value === "string") {
              if (!valid && validate?.(e.target.value)) {
                setValid(true);
              }
              if (format) e.target.value = format(e.target.value);
              if (!focused) setOpen(!!e.target.value);
            }

            onChange?.(e, { injection });
          }}
          onFocus={(e) => {
            setOpen(true);
            setFocused(true);

            onFocus?.(e, { injection });
          }}
          onBlur={(e) => {
            if (e.target) setOpen(!!e.target.value);
            setFocused(false);
            if (validate && !validate(e.target.value)) {
              setValid(false);
            }
            onBlur?.(e, { injection });
          }}
          {...props}
          {...(focused && focusProps)}
          {...(open && openProps)}
          {...(valid && validProps)}
        />
        <label htmlFor={props.name}>{_label}</label>
        <span
          className="full"
          style={{ opacity: 0, position: "absolute", pointerEvents: "none" }}
        >
          {label}
        </span>
        {actions && (
          <div className={styles.actions}>
            {(actions as TextInputAction[]).map(
              ({ component, ..._props }, key) => (
                <div className={styles.action} key={key} {..._props}>
                  {component}
                </div>
              )
            )}
          </div>
        )}
        {children}
      </div>
    );
  }
);

type SelectMenuProps = React.HTMLProps<HTMLSelectElement> & {
  className?: string;
  styleType?: "default" | "underline";
  options?: DeepReadonly<
    | {
        [key: string]: string | { label: string; onSelect?(): void };
      }
    | [key: string, value?: string][]
  >;
  onChange?(
    e: React.SyntheticEvent<HTMLSelectElement>,
    ctx: { injection: any }
  ): void;
  containerProps?: {
    id?: string;
  };
  validate?: (str: string) => boolean;
  // TODO: remove all form injection logic
  injection?: any;
  onInitial?(value: string): void;
  setValue?(value: string): void;
};

export const SelectMenu: React.FC<SelectMenuProps> = ({
  className,
  options,
  styleType = "default",
  containerProps = {},
  label,
  injection,
  onChange,
  onInitial,
  setValue,
  defaultValue,
  ...props
}) => {
  const [open, setOpen] = useState(!!defaultValue || !!props.value);
  const ref = useRef(null);
  const Option = ({ name, ..._props }) =>
    el(
      "option",
      {
        ..._props,
      },
      name
    );
  function onSelect(value, e) {
    setOpen(!!value);

    options[value]?.onSelect?.();

    onChange?.(e, { injection });
  }
  useUpdateEffect(() => {
    // TODO: Do this with a real event
    onSelect(props.value, { target: ref.current, currentTarget: ref.current });
  }, [props.value]);

  useEffect(() => {
    const value = (defaultValue ||
      (label
        ? ""
        : (options instanceof Array
            ? options
            : Object.keys(options))[0])) as string;

    setValue?.(value);
    onInitial?.(value);
  }, []);

  return (
    <div
      className={combineClasses([
        styles["select-menu"],
        styles[styleType],
        className,
        { [styles.open]: open },
      ])}
      id={containerProps.id}
    >
      {
        // Select element should always be the first child
        el(
          "select",
          {
            ref,
            ...(typeof props.value === "undefined" && {
              defaultValue: defaultValue || "",
            }),
            ...props,
            onChange(e) {
              onSelect(e.currentTarget.value, e);
            },
          },
          [
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            ...(label ? [<option disabled key={-1} />] : []),
            // TODO: Fix this typing (incompatible call signatures), DeepReadonly does not fix this
            ...((options instanceof Array
              ? options
              : Object.entries(options)) as any).map(([value, name], key) =>
              Option({
                name: !name
                  ? value
                  : typeof name === "string"
                  ? name
                  : name.label,
                value,
                key,
              })
            ),
          ]
        )
      }
      <label htmlFor={props.name}>{label}</label>
    </div>
  );
};

export function SwipeAction({
  className,
  label,
  height = "40px",
  sliderScale = 1,
  style,
  complete: { percent: completePercent = 0.9, minArea = 0 } = {},

  onSwipe,
}) {
  const ref = useRef(null);
  useEffect(() => {
    let initialLeft;
    const slider = ref.current;
    return createDragHandler({
      ref,
      onStart() {
        initialLeft = +getComputedStyle(slider).left.slice(0, -2);
        gsap.to(slider.parentElement, {
          css: {
            borderTopLeftRadius: `${height}px`,
            borderBottomLeftRadius: `${height}px`,
          },
        });
      },
      onDrag({ pos }) {
        slider.style.left = `${clamp(initialLeft + pos.dx, {
          min: 0,
          max: slider.parentElement.clientWidth - slider.clientWidth,
        })}px`;
      },
      onDrop() {
        if (
          slider.offsetLeft /
            (slider.parentElement.clientWidth - slider.clientWidth) >
            completePercent ||
          slider.parentElement.clientWidth -
            slider.clientWidth -
            slider.offsetLeft <
            minArea
        ) {
          onSwipe?.();
        } else {
          gsap.to(slider, {
            ease: "bounce",
            css: { left: 0 },
          });
          gsap.to(slider.parentElement, {
            css: {
              borderTopLeftRadius: `${height}px`,
              borderBottomLeftRadius: `${height}px`,
            },
          });
        }
      },
    }).cleanup;
  }, []);
  return (
    <div
      className={combineClasses(styles["swipe-action"], className)}
      style={{
        height,
        borderTopLeftRadius: `${height}px`,
        borderBottomLeftRadius: `${height}px`,
        ...style,
      }}
    >
      <div
        className={styles.slider}
        ref={ref}
        style={{
          height,
          width: height,
          transform: `scale(${sliderScale})`,
        }}
      />
      <div className={styles.label}>{label}</div>
    </div>
  );
}

// horizontal radio button
// options is a list of map with label and value key for each option
export function HorizontalRadioButton({
  name,
  options,
  onChange,
  injection,
  ...props
}) {
  useEffect(
    () =>
      onRadioChange({
        handler({ target }) {
          onChange({ target }, { injection });
        },
      }).cleanup,
    []
  );

  const inputs = options.map((option) => (
    <div key={option.value}>
      <input
        type="radio"
        className={styles["radio-button"]}
        name={name}
        value={option.value}
        id={option.value}
      />
      <label className={styles["for-radio-button"]} htmlFor={option.value}>
        {option.label}
      </label>
    </div>
  ));
  return <div className={styles["horizontal-radio-button"]}>{inputs}</div>;
}
