import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { web } from "@kikoff/proto/src/protos";
import { webRPC } from "@kikoff/proto/src/rpc";
import { handleFailedStatus, handleProtoStatus } from "@kikoff/utils/src/proto";
import Table from "@kikoff/utils/src/table";

import { getBackendExperimentCookie } from "@src/experiments/context";
import { RootState } from "@store";

import { createLoadableSelector, thunk } from "../utils";

const initialState = {
  offerByProductName: {} as MarketplaceOffer.ByProductName,
  offerProductNamesByRequestType: {} as Record<
    web.public_.ListMarketplaceOffersRequest.RequestType,
    MarketplaceOffer.ProductName[]
  >,
};

export type MarketplaceState = typeof initialState;

const marketplaceSlice = createSlice({
  name: "marketplace",
  initialState,
  reducers: {
    setMarketplaceOffers(
      state,
      {
        payload,
      }: PayloadAction<{
        marketplaceOffers: MarketplaceOffer[];
        requestType: web.public_.ListMarketplaceOffersRequest.RequestType;
      }>
    ) {
      state.offerProductNamesByRequestType[
        payload.requestType
      ] = payload.marketplaceOffers.map(({ productName }) => productName);

      Object.assign(
        state.offerByProductName,
        MarketplaceOffer.ByProductName.fromList(payload.marketplaceOffers)
      );
    },
    removeMarketplaceOffer(
      state,
      { payload }: PayloadAction<MarketplaceOffer.ProductName>
    ) {
      for (const [requestType, offerProductNames] of Object.entries(
        state.offerProductNamesByRequestType
      )) {
        const offerIndex = offerProductNames.indexOf(payload);
        if (offerIndex === -1) continue;
        state.offerProductNamesByRequestType[requestType].splice(offerIndex, 1);
      }
    },
  },
});

const { actions } = marketplaceSlice;
export const {} = actions;
export default marketplaceSlice.reducer;

export const selectMarketplaceOffers = createLoadableSelector(
  (requestType: web.public_.ListMarketplaceOffersRequest.RequestType) => (
    state: RootState
  ) =>
    state.marketplace?.offerProductNamesByRequestType[requestType]?.map(
      (productName) => state.marketplace.offerByProductName[productName]
    ),
  {
    loadAction: (requestType) => fetchMarketplaceOffers(requestType),
    selectLoaded: (requestType) => (state: RootState) =>
      !!state.marketplace?.offerProductNamesByRequestType[requestType],
  }
);

export const fetchMarketplaceOffers = Object.assign(
  (requestType) =>
    thunk((dispatch) => {
      return webRPC.Marketplace.listMarketplaceOffers({
        requestType,
      }).then(
        handleProtoStatus({
          SUCCESS({ offers }) {
            dispatch(
              actions.setMarketplaceOffers({
                marketplaceOffers: offers,
                requestType,
              })
            );
            return offers;
          },
          _DEFAULT: handleFailedStatus("Failed to load live utilization."),
        })
      );
    }),
  {
    ifNotPresent: (requestType) =>
      thunk((dispatch, getState) =>
        Promise.resolve(
          selectMarketplaceOffers(requestType)(getState()) ||
            dispatch(fetchMarketplaceOffers(requestType))
        )
      ),
  }
);

export const dismissMarketplaceOffer = (
  productName: MarketplaceOffer.ProductName
) =>
  thunk((dispatch, getState) =>
    webRPC.Marketplace.dismissMarketplaceOffer({ productName }).then(
      handleProtoStatus({
        SUCCESS() {
          dispatch(actions.removeMarketplaceOffer(productName));
        },
        _DEFAULT: handleFailedStatus("Failed to dismiss Marketplace offer."),
      })
    )
  );

export namespace Marketplace {}

export const isMarketplaceEnabled = () =>
  getBackendExperimentCookie("marketplaceCreditCards") === "treatment";

export type MarketplaceOffer = web.public_.IMarketplaceOffer;
export namespace MarketplaceOffer {
  export type ProductName = string & {};

  export type ByProductName = Record<
    MarketplaceOffer.ProductName,
    MarketplaceOffer
  >;

  export namespace ByProductName {
    export const fromList = (marketplaceOffers: MarketplaceOffer[]) =>
      Table.createIndex(marketplaceOffers, ["productName"]);
  }

  export type RequestType = typeof web.public_.ListMarketplaceOffersRequest.RequestType[keyof typeof web.public_.ListMarketplaceOffersRequest.RequestType];
  export const RequestType =
    web.public_.ListMarketplaceOffersRequest.RequestType;
}
