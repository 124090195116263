import { useEffect } from "react";
import { batch, useSelector } from "react-redux";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { google, web } from "@kikoff/proto/src/protos";
import { webRPC } from "@kikoff/proto/src/rpc";
import { promiseDelay } from "@kikoff/utils/src/general";
import { handleFailedStatus, handleProtoStatus } from "@kikoff/utils/src/proto";

import { AppThunk, RootState } from "@store";
import analytics from "@util/analytics";

import { createLoadableSelector, thunk } from "../utils";

import { selectCreditAccount } from "./credit_line";
import { Plan } from "./subscription";
import { fetchUser } from "./user";

const initialState = {
  products: [] as web.public_.IProduct[],
  orders: null as web.public_.IOrder[],
  bag: {
    items: [] as web.public_.IOrderItem[],
    autoRenew: null as web.public_.IAutoRenew,
    firstPaymentDate: null as google.protobuf.ITimestamp,
  },
  checkoutPreview: null as web.public_.ICheckoutPreviewResponse,
  checkoutResponse: null as web.public_.ICheckoutResponse,
  previewDisputeReactivation: null as web.public_.IPreviewDisputeReactivationResponse,
  upgradeToPremiumResponse: null as web.public_.IUpgradeToPremiumResponse,
  previewChangeSubscriptionPlan: {} as Partial<
    Record<
      web.public_.SubscriptionPlan,
      web.public_.IPreviewChangeSubscriptionPlanResponse
    >
  >,
  subscriptions: null as web.public_.IUserSubscription[],
  subscriptionInvoices: null as web.public_.IUserSubscriptionInvoice[],
};

export type ShoppingState = typeof initialState;

const shoppingSlice = createSlice({
  name: "shopping",
  initialState,
  reducers: {
    setCatalog(state, { payload }: PayloadAction<ShoppingState["products"]>) {
      state.products = payload;
    },
    updateBag(
      state,
      { payload }: PayloadAction<RequireAtLeastOne<ShoppingState["bag"]>>
    ) {
      Object.assign(state.bag, payload);
    },
    setCheckoutPreview(
      state,
      { payload }: PayloadAction<ShoppingState["checkoutPreview"]>
    ) {
      state.checkoutPreview = payload;
    },
    setCheckoutResponse(
      state,
      { payload }: PayloadAction<ShoppingState["checkoutResponse"]>
    ) {
      state.checkoutResponse = payload;
    },
    setPreviewChangeSubscriptionPlan(
      state,
      { payload }: PayloadAction<ShoppingState["previewChangeSubscriptionPlan"]>
    ) {
      Object.assign(state.previewChangeSubscriptionPlan, payload);
    },
    setOrders(state, { payload }: PayloadAction<ShoppingState["orders"]>) {
      state.orders = payload;
    },
    setSubscriptions(
      state,
      { payload }: PayloadAction<ShoppingState["subscriptions"]>
    ) {
      state.subscriptions = payload;
    },
    setSubscriptionInvoices(
      state,
      { payload }: PayloadAction<ShoppingState["subscriptionInvoices"]>
    ) {
      state.subscriptionInvoices = payload;
    },
  },
});

const { actions } = shoppingSlice;
export const { updateBag, setCheckoutPreview } = actions;
export default shoppingSlice.reducer;

export const getProductFromCheckoutPreview = (
  checkoutPreview: web.public_.ICheckoutPreviewResponse
) => checkoutPreview?.order.orderItems[0]?.product;

export const selectOrderBy = <Prop extends keyof web.public_.IOrder>(
  field: Prop,
  match: web.public_.IOrder[Prop]
) => (state: RootState) =>
  state.shopping.orders.find((order) => order[field] === match);

export const selectProductBy = <Prop extends keyof web.public_.IProduct>(
  field: Prop,
  match: web.public_.IProduct[Prop]
) => (state: RootState) => {
  const { products, previewChangeSubscriptionPlan } = state.shopping;

  const product = products.find((product) => product[field] === match);
  if (product) return product;

  const plan = Object.values(previewChangeSubscriptionPlan).find(
    ({ product }) => product[field] === match
  );

  return plan?.product;
};

export const selectProductForPlan = createLoadableSelector(
  (plan: keyof typeof web.public_.SubscriptionPlan) => (state: RootState) =>
    state.shopping.products.find(
      (product) =>
        product.plan === web.public_.SubscriptionPlan[plan] &&
        product.subscriptionDurationMonths === 12
    ),
  { loadAction: () => updateCatalog() }
);

export const selectActiveSubscription = createLoadableSelector(
  () => (state: RootState) =>
    state.shopping.subscriptions?.find(
      ({ status }) => status === web.public_.UserSubscription.Status.ACTIVE
    ),
  { loadAction: () => updateOrders() }
);

export const selectIsBasic = createLoadableSelector(
  () => (state: RootState) => {
    const subscriptions = state.shopping.subscriptions;
    const subscription = selectActiveSubscription()(state);

    // Return nullish if subscriptions aren't loaded
    return (
      subscriptions && subscription?.plan === web.public_.SubscriptionPlan.BASIC
    );
  },
  {
    loadAction: () => updateOrders(),
    selectLoaded: () => (state) => state.shopping.subscriptions,
  }
);

export const selectIsPremium = createLoadableSelector(
  () => (state: RootState) => {
    const subscriptions = state.shopping.subscriptions;
    const subscription = selectActiveSubscription()(state);

    // Return nullish if subscriptions aren't loaded
    return (
      subscriptions &&
      subscription?.plan === web.public_.SubscriptionPlan.PREMIUM
    );
  },
  {
    loadAction: () => updateOrders(),
    selectLoaded: () => (state) => state.shopping.subscriptions,
  }
);

export const selectIsUltimate = createLoadableSelector(
  () => (state: RootState) => {
    const subscriptions = state.shopping.subscriptions;
    const subscription = selectActiveSubscription()(state);

    // Return nullish if subscriptions aren't loaded
    return (
      subscriptions &&
      subscription?.plan === web.public_.SubscriptionPlan.ULTIMATE
    );
  },
  {
    loadAction: () => updateOrders(),
    selectLoaded: () => (state) => state.shopping.subscriptions,
  }
);

export const selectIsPremiumOrUltimate = createLoadableSelector(
  () => (state: RootState) => {
    const subscriptions = state.shopping.subscriptions;
    const subscription = selectActiveSubscription()(state);

    // Return nullish if subscriptions aren't loaded
    return (
      subscriptions &&
      (subscription?.plan === web.public_.SubscriptionPlan.PREMIUM ||
        subscription?.plan === web.public_.SubscriptionPlan.ULTIMATE)
    );
  },
  {
    loadAction: () => updateOrders(),
    selectLoaded: () => (state) => state.shopping.subscriptions,
  }
);

export const selectWasPremiumOrUltimate = createLoadableSelector(
  () => (state: RootState) => {
    const BASIC_PRICE_CENTS = 6000;
    const subscriptionInvoices = state.shopping.subscriptionInvoices;
    return subscriptionInvoices.some(
      ({ totalPriceCents }) =>
        totalPriceCents != null && totalPriceCents > BASIC_PRICE_CENTS
    );
  },
  {
    loadAction: () => updateOrders(),
    selectLoaded: () => (state) => state.shopping.subscriptionInvoices,
  }
);

export const selectPlanName = createLoadableSelector(
  () => (state: RootState) => {
    const subscriptions = state.shopping.subscriptions;
    const subscription = selectActiveSubscription()(state);

    if (!subscriptions) {
      return null;
    }

    return Plan.name(subscription?.plan);
  },
  {
    loadAction: () => updateOrders(),
    selectLoaded: () => (state) => state.shopping.subscriptions,
  }
);

export const selectCreditServiceProduct = createLoadableSelector(
  (plan: keyof typeof web.public_.SubscriptionPlan) => (state) =>
    state.shopping.products.find(
      ({ type, subscriptionDurationMonths, plan: _plan }) =>
        type === web.public_.Product.Type.SUBSCRIPTION &&
        _plan === (web.public_.SubscriptionPlan[plan] as any) &&
        subscriptionDurationMonths === 12
    ),
  { loadAction: (plan) => updateCatalog(true) }
);

export const selectChangeSubscriptionPlanPreview = createLoadableSelector(
  (plan: Plan) => (state) =>
    state.shopping.previewChangeSubscriptionPlan[Plan.toProto[plan]],
  {
    loadAction: (plan) =>
      fetchPreviewChangeSubscriptionPlan(Plan.toProto[plan]),
  }
);

export const selectCheckoutPreview = createLoadableSelector(
  (productToken: string) => (state) => state.shopping.checkoutPreview,
  {
    loadAction: (productToken) => fetchCheckoutPreview(productToken),
    selectLoaded: (productToken) => (state) =>
      productToken &&
      state.shopping.checkoutPreview?.order.orderItems[0].product.token ===
        productToken,
  }
);

export const selectCreditServiceCheckoutPreview = createLoadableSelector(
  (plan: keyof typeof web.public_.SubscriptionPlan) => (state) => {
    const productToken = selectCreditServiceProduct(plan)(state)?.token;
    return (
      productToken &&
      state.shopping.checkoutPreview?.order.orderItems[0].product?.token ===
        productToken &&
      state.shopping.checkoutPreview
    );
  },
  {
    loadAction: (plan) => fetchCreditServiceCheckoutPreview(plan),
  }
);

export const updateCatalog = Object.assign(
  (storeRequest: boolean = false) => (dispatch, getState) => {
    // Pass in store request context for catalog update requests from the store. This will filter out
    // subscription products for users who already have active subscriptions.
    const context = storeRequest
      ? web.public_.ListCatalogRequest.ListCatalogueContext.KIKOFF_STORE
      : null;
    return webRPC.Shopping.listCatalog({
      context,
    }).then(
      handleProtoStatus({
        SUCCESS(data) {
          dispatch(actions.setCatalog(data.products));
          return data;
        },
        _DEFAULT: handleFailedStatus("Failed to fetch catalog"),
      })
    );
  },
  {
    ifNotPresent: () =>
      thunk((dispatch, getState) => {
        if (getState().shopping.products.length > 0)
          return Promise.resolve({ products: getState().shopping.products });
        return dispatch(updateCatalog());
      }),
  }
);
interface shopCheckoutProps {
  autoPay?: web.public_.AutoPay.ISettings;
  isRenewal?: boolean;
}

export const shopCheckout = (
  { autoPay, isRenewal }: shopCheckoutProps = {
    autoPay: null,
    isRenewal: false,
  }
): AppThunk<Promise<void>> => (dispatch, getState) => {
  const shoppingBag = getState().shopping.bag;

  return webRPC.Shopping.checkout({
    autoRenew: shoppingBag.autoRenew,
    productToken: shoppingBag.items[0].product.token,
    firstPaymentDate: shoppingBag.firstPaymentDate,
    autoPay,
    isRenewal,
  }).then(
    handleProtoStatus({
      async SUCCESS(data) {
        dispatch(actions.setCheckoutResponse(data));
        // Refreshed user sometimes doesn't have credit line, give server time to update
        await promiseDelay(500);
        await Promise.all([
          // Need to wallet in addition to credit line
          dispatch(fetchUser.products()),
          dispatch(updateOrders()),
        ]);
        analytics.convert("Credit Account Open");
      },
      _DEFAULT: handleFailedStatus("Failed to checkout"),
    })
  );
};

export const initDemoShopping = (data): AppThunk => (dispatch) => {
  dispatch(actions.setOrders(data.orders));
  dispatch(actions.setSubscriptions(data.userSubscriptions));
};

export const updateOrders = (): AppThunk<
  Promise<web.public_.ListOrdersResponse>
> => (dispatch) =>
  webRPC.Shopping.listOrders({}).then(
    handleProtoStatus({
      SUCCESS(data) {
        batch(() => {
          dispatch(actions.setOrders(data.orders));
          dispatch(actions.setSubscriptions(data.userSubscriptions));
          dispatch(
            actions.setSubscriptionInvoices(data.userSubscriptionInvoices)
          );
        });

        return data;
      },
      _DEFAULT: handleFailedStatus("Failed to fetch orders"),
    })
  );
export const fetchCheckoutPreview = (
  productToken: string,
  { isRenewal = false } = {}
): AppThunk<Promise<web.public_.CheckoutPreviewResponse>> => async (dispatch) =>
  webRPC.Shopping.checkoutPreview({ productToken, isRenewal }).then(
    handleProtoStatus({
      SUCCESS(data) {
        dispatch(actions.setCheckoutPreview(data));
        return data;
      },
      _DEFAULT: handleFailedStatus("Failed to fetch checkout preview"),
    })
  );

export const fetchCreditServiceCheckoutPreview = (
  plan: keyof typeof web.public_.SubscriptionPlan
) =>
  thunk((dispatch, getState) =>
    dispatch(updateCatalog.ifNotPresent()).then(async () => {
      const product = selectCreditServiceProduct(plan)(getState());

      const preview = await dispatch(fetchCheckoutPreview(product.token));

      dispatch(
        updateBag({
          items: [{ product, quantity: 1 }],
          autoRenew: {
            status: preview.autoRenew?.status,
          },
        })
      );
    })
  );

export const fetchPreviewChangeSubscriptionPlan = (
  plan: web.public_.SubscriptionPlan
): AppThunk<
  Promise<web.public_.IPreviewChangeSubscriptionPlanResponse>
> => async (dispatch) =>
  webRPC.Shopping.previewChangeSubscriptionPlan({
    subscriptionPlan: plan,
  }).then(
    handleProtoStatus({
      SUCCESS(data) {
        dispatch(
          actions.setPreviewChangeSubscriptionPlan({
            [plan]: data,
          })
        );
        return data;
      },
      _DEFAULT: handleFailedStatus("Failed to fetch checkout preview"),
    })
  );

export const changeSubscriptionPlan = (
  plan: web.public_.SubscriptionPlan,
  source: string
): AppThunk<Promise<web.public_.ChangeSubscriptionPlanResponse>> => async (
  dispatch
) =>
  webRPC.Shopping.changeSubscriptionPlan({
    subscriptionPlan: plan,
    source,
  }).then(
    handleProtoStatus({
      SUCCESS(data) {
        dispatch(fetchUser.creditLine());
        dispatch(fetchUser.features());
        dispatch(updateOrders());
        return data;
      },
      _DEFAULT: handleFailedStatus("Failed to update subscription"),
    })
  );

export const useCreditUpsellGate = (reject: () => void) => {
  const preventUpsell = useSelector(
    (state) => state.page.mobileFlavor === "theseus"
  );

  useEffect(() => {
    if (preventUpsell) reject();
  });

  return { preventRender: preventUpsell };
};

export const usePremiumUpgradeInfo = () => {
  const creditLine = useSelector(selectCreditAccount());
  const minimumPaymentCents =
    creditLine?.creditLineAgreement?.minimumPaymentAmountCents ?? 500;

  const [premiumPreview] = useSelector(
    selectChangeSubscriptionPlanPreview.load("premium")
  );
  const product = premiumPreview?.product;

  const creditLimitCents =
    product?.creditLineAgreement?.defaultCreditLimitCents;

  const additionalAmountCents =
    product?.monthlyPaymentCents - minimumPaymentCents;

  return {
    allowUpgrade: premiumPreview?.allowUpgrade ?? false,
    creditLimitCents,
    additionalAmountCents,
  };
};

export const useAutoRenewInfo = (
  subscription: web.public_.IUserSubscription
) => {
  const autoRenewStatus = subscription?.autoRenew?.status;
  const autoRenewEnabled =
    autoRenewStatus === web.public_.AutoRenew.Status.ENABLED;
  const autoRenewDate = subscription?.autoRenew?.renewalAt?.seconds * 1000;

  return {
    autoRenewStatus,
    autoRenewEnabled,
    autoRenewDate,
  };
};
