import { web } from "@kikoff/proto/src/protos";

export default function findRecommendationForTaskType(
  taskType?: web.public_.CoachingTask.Type,
  recommendations?: web.public_.IRecommendation[]
): {
  recommendation: web.public_.IRecommendation;
  invalidRecommendation: boolean;
} {
  const {
    CBL_HIGH_SCORE,
    CBL_LOW_SCORE,
    CA_PAYMENT_30_OVERDUE,
    CA_PAYMENT_90_OVERDUE,
    PAYMENT_90_OVERDUE,
    PAYMENT_120_OVERDUE,
    DISPUTES_LATE_PAYMENT,
    DISPUTES_OLD,
    DISPUTES_REDISPUTE,
  } = web.public_.Recommendation.Variant;

  const recommendationAccounts = (() => {
    switch (taskType) {
      case web.public_.CoachingTask.Type.DISPUTE_LATE_PAYMENT: {
        const recommendation = recommendations.find((recommendation) =>
          [DISPUTES_LATE_PAYMENT].includes(recommendation.variant)
        );
        const recommendationAccount =
          recommendation?.subject?.disputesLatePayment;

        return {
          recommendation,
          invalidRecommendation: !recommendationAccount,
        };
      }
      case web.public_.CoachingTask.Type.DISPUTE_OLD_ACCOUNT: {
        const recommendation = recommendations.find((recommendation) =>
          [DISPUTES_OLD].includes(recommendation.variant)
        );
        const recommendationAccount =
          recommendation?.subject?.disputesOld?.accounts?.[0];

        return {
          recommendation,
          invalidRecommendation: !recommendationAccount,
        };
      }
      case web.public_.CoachingTask.Type.DISPUTE_REDISPUTE: {
        const recommendation = recommendations.find((recommendation) =>
          [DISPUTES_REDISPUTE].includes(recommendation.variant)
        );
        const recommendationAccount =
          recommendation?.subject?.disputesRedispute?.accounts?.[0];

        return {
          recommendation,
          invalidRecommendation: !recommendationAccount,
        };
      }
      case web.public_.CoachingTask.Type.LATE_ACCOUNT: {
        const recommendation = recommendations.find((recommendation) =>
          [PAYMENT_90_OVERDUE, PAYMENT_120_OVERDUE].includes(
            recommendation.variant
          )
        );
        const recommendationAccount =
          recommendation?.subject?.payment_90Overdue ??
          recommendation?.subject?.payment_120Overdue;

        return {
          recommendation,
          invalidRecommendation: !recommendationAccount,
        };
      }
      case web.public_.CoachingTask.Type.LATE_CA_PAYMENT: {
        const recommendation = recommendations.find((recommendation) =>
          [CA_PAYMENT_30_OVERDUE, CA_PAYMENT_90_OVERDUE].includes(
            recommendation.variant
          )
        );
        const recommendationAccount =
          recommendation?.subject?.caPayment_30Overdue ??
          recommendation?.subject?.caPayment_90Overdue;

        return {
          recommendation,
          invalidRecommendation: !recommendationAccount,
        };
      }
      case web.public_.CoachingTask.Type.OPEN_CBL: {
        const recommendation = recommendations.find((recommendation) =>
          [CBL_HIGH_SCORE, CBL_LOW_SCORE].includes(recommendation.variant)
        );
        const recommendationAccount =
          recommendation?.subject?.cblLowScore ??
          recommendation?.subject?.cblHighScore;

        return {
          recommendation,
          invalidRecommendation: !recommendationAccount,
        };
      }
      default: {
        return {
          recommendation: undefined,
          invalidRecommendation: undefined,
        };
      }
    }
  })();

  return recommendationAccounts;
}
